import React from 'react'
import classNames from '../../utils/classNames'
import playIcon from '../../assets/playIcon.png'
import LoadingSpinner from '../../components/presentation/LoadingSpinner'

class SlideVideo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      playPending: false,
      canPlay: false,
      isPlaying: !props.getIsMobile()//!this.props.isMobile//!getIsMobile()
    }
    this.isMobile = props.getIsMobile()
    this.windowWidth = window.innerWidth
  }

  componentDidMount() {
    // console.log('slide video mounted', this.props.slide)
    window.addEventListener('resize', this.handleResize)
  }

  handleResize = (e) => {
    //if width resized and went from mobile to not mobile or vice versa
    const newWidth = window.innerWidth
    const newIsMobile = this.props.getIsMobile()
    const switchedToMobile = !this.isMobile && this.props.getIsMobile()
    const switchedToDesktop = this.isMobile && !this.props.getIsMobile()
    if(switchedToMobile) { //if switched to mobile, set isplaying to false,
      this.pauseVideo()
    } else if(switchedToDesktop) {
      this.playVideo()
    }
  }

  setCanPlay = () => {
    // console.log(this.props.slide, 'setting can play')
    this.setState({canPlay: true})
  }

  playVideo = () => {
    if(!this.videoEl) return
    try {
      this.videoEl.currentTime = 0 //reset to 0
      const playPromise = this.videoEl.play()
      // In browsers that don’t yet support this functionality,     // playPromise won’t be defined.
      if (playPromise !== undefined) {
        this.setState({playPending: true})
        playPromise.then(() => {
          // Automatic playback started!
          this.setState({isPlaying: true, playPending: false})
        }).catch((error)  => {
          // Automatic playback failed.
          console.log('playback failed', error)
          this.setState({playPending: false})
        });
      } else {
        this.setState({isPlaying: true, playPending: false})
      }

    } catch (e) {
      console.log('caught error trying to play video', e)
    }

  }

  pauseVideo = () => {
    if(!this.videoEl) return
    try {
      this.videoEl.pause()
      this.setState({isPlaying: false})
    } catch(e) {
      console.log('caught error pausing video', e)
    }

  }

  onVideoEnd = () => {
    this.setState({
      isPlaying: false,
      playPending: false
    })
  }

  render() {
    const {
      video,
      poster,
      isActiveSlide,
      // isMobile,
      getIsMobile
    } = this.props
    const {
      isPlaying,
      playPending,
      canPlay
    } = this.state;

    this.isMobile = getIsMobile()
    const isMobile = this.isMobile
    // console.log('isMobile', isMobile, 'isplaying', isPlaying)
    return (
      <div className={classNames("section-slide-video", { "fade-animation": isActiveSlide })} style={{position: 'relative'}}>
        <video
          ref={(node) => this.videoEl = node}
          poster={poster}
          preload="metadata"
          onCanPlay={this.setCanPlay}
          autoPlay={!isMobile} //only autoplay on desktop
          //onEnded={isMobile ? this.onVideoEnd : () => {}}// on moble,
          loop//={!isMobile} //since it cant play inline on mobile, dont loop
          muted
          inline="true"
          playsinline="true"
          src={video}
          style={{height: '100%'}}
        />
        {isMobile && !isPlaying && ( //if were on mobile and video is not playing, show poster image
          <>
            <img
              src={poster}
              onClick={playPending ? () => {} : this.playVideo}
              style={{
                height: '100%',
                position: 'absolute',
              }}/>
            {!isPlaying && <div
              style={{
                color: 'white',
                position: 'absolute',
                fontSize: 32,
                fontWeight: 'bold',
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              onClick={playPending ? () => {} : this.playVideo}
                           >

              {(playPending) ?
                <LoadingSpinner />
              : <img
                src={playIcon}
                style={{
                  height: '40%',
                  margin: 'auto 0px',

                }}
                />
              }
            </div>}
          </>
        )}
      </div>
    )
  }
}


export default SlideVideo
