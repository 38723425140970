import React from 'react';
import './NavBar.css'
import classNames from '../../../utils/classNames'
import Hamburger from '../Hamburger'
import Sidebar from '../Sidebar'

class NavBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sideBarOpen: false
    }
  }

  sideBarToggle = () => {
    this.setState({
      sideBarOpen: !this.state.sideBarOpen
    })
  }

  render() {
    const {
      showNavBar,
      onNavLogoClick,
    } = this.props;
    return (
      <div className={classNames("navbar", {
        "hide": showNavBar
      })}>
        <div className="logo" onClick={onNavLogoClick}>
          NUMBERS HAVE LIFE
        </div>
        <div className="right-container">
          {/* <Hamburger className="hamburger" fill={'#fff'} onClick={() => this.setState({sideBarOpen: !this.state.sideBarOpen}) }/> */}
          <div className="phrase">
            Listen to scientists. Get Vaccinated. 
          </div>
          <a className="contact" target='_blank' rel="noreferrer" href='mailto:contactnumbershavelife@gmail.com'>
            CONTACT
          </a>
        </div>
        {/* <Sidebar show={this.state.sideBarOpen} /> */}
        {/* <div className={classNames("gradient", {
          "show": this.state.sideBarOpen
        })} onClick={() => this.setState({sideBarOpen: false}) }/> */}
      </div>
    )
  }
}

export default NavBar
