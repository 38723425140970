import React from 'react';

import Typography from '../../components/presentation/Typography'
import ScrollIndicator from '../../components/presentation/ScrollIndicator'
import NavBar from '../../components/presentation/NavBar'
import Download from '../../components/presentation/ShareIcons/Download'
import Facebook from '../../components/presentation/ShareIcons/Facebook'
import Instagram from '../../components/presentation/ShareIcons/Instagram'
import Twitter from '../../components/presentation/ShareIcons/Twitter'
import NavigationDots from '../../components/presentation/NavigationDots'
import Close from '../../components/presentation/CloseIcon'

import './Home.css'

// import testMP4 from '../../assets/test-asset.mp4'
import backToTopIcon from '../../assets/back-to-top.svg'
import debounce from '../../utils/debounce'
import classNames from '../../utils/classNames'
import SlideVideo from './SlideVideo'
const TITLE_SLIDE = {

}

const TWITTER_LINKS = {

}

const getFacebookShareLink = (url) => {
  return `https://www.facebook.com/sharer/sharer.php?u=${url}`
}

const getTwitterShareLink = (url, text) => {
  return `https://twitter.com/intent/tweet?url=${url}&text=${text}`
}

const getPinterestShareLink = (url, text, media) => {
  return `https://pinterest.com/pin/create/button/?url=${url}&media=${media}&description=${text}`
}

const getEmailShareLink = (url, subject) => {
  return `mailto:info@example.com?&subject=${subject}&body=${url}`
}

// https://stackoverflow.com/questions/32225904/programmatical-click-on-a-tag-not-working-in-firefox
export const downloadAsset = (
  assetHref,
  name,
  extension,
) => {
  const downloadAnchor = document.createElement('a');
  downloadAnchor.href = assetHref;
  downloadAnchor.target = '_parent';
  downloadAnchor.download = `${name}.${extension}`;
  document.body.appendChild(downloadAnchor); // need to attach to document for firefox
  downloadAnchor.click();
  downloadAnchor.remove();
};


const SECTIONS = [
  {
    type: 'intro',
    title: <><b>NUMBERS HAVE LIFE;</b><br/> THEY'RE NOT JUST <br/> SYMBOLS ON PAPER.</>,
    subtitle: 'SHAKUNTALA DEVI'//'Shakuntala Devi'
  },
  {
    type: 'intro',
    title: <>PEOPLE HAVE DIED OF<br/> COVID-19 IN THE USA</>,
    number: 600000,
    subtitle: 'LET\'S PUT THAT IN CONTEXT'
  },
  {
    heading: <>TO <b>DRIVE THEM</b> IT WOULD TAKE</>,
    number: 12500,
    unit: 'SCHOOL BUSES',
    finePrint: 'The average school bus can fit 48 adults.',
    video: 'https://d2k57iveb9mkqe.cloudfront.net/section-1.mp4',
    shareVideo: 'https://d2k57iveb9mkqe.cloudfront.net/section-1-share.mp4',
    poster: 'https://d2k57iveb9mkqe.cloudfront.net/section-1-poster.png',
    applyNumberAnimation: true,
    twitter: 'https://twitter.com/NumbersHaveLife/status/1336395469447114753?s=20',
    instagram: 'https://www.instagram.com/p/CIjAtBGBeo1/?utm_source=ig_web_copy_link',
    facebook: 'https://www.instagram.com/p/CIjAtBGBeo1/?utm_source=ig_web_copy_link'
  },
  {
    heading: <>THEIR <b>COMBINED WEIGHT</b> IS EQUAL TO</>,
    number: 8550,
    unit: 'AFRICAN ELEPHANTS',
    finePrint: 'The average American weighs 171lbs and the average African elephant 12,000lbs.',
    video: 'https://d2k57iveb9mkqe.cloudfront.net/section-2.mp4',
    shareVideo: 'https://d2k57iveb9mkqe.cloudfront.net/section-2-share.mp4',
    poster: 'https://d2k57iveb9mkqe.cloudfront.net/section-2-poster.png',
    applyNumberAnimation: true,
    twitter: 'https://twitter.com/NumbersHaveLife/status/1336395669603577856?s=20',
    instagram: 'https://www.instagram.com/p/CIjA86aBioT/?utm_source=ig_web_copy_link',
    facebook: 'https://www.instagram.com/p/CIjA86aBioT/?utm_source=ig_web_copy_link'
  },
  {
    heading: <>TO <b>FLY THEM</b> IT WOULD TAKE</>,
    number: 3530,
    unit: 'AIRBUS A320\'S',
    finePrint: 'The average Airbus A320 can fit 170 adults.',
    video: 'https://d2k57iveb9mkqe.cloudfront.net/section-3.mp4',
    shareVideo: 'https://d2k57iveb9mkqe.cloudfront.net/section-3-share.mp4',
    poster: 'https://d2k57iveb9mkqe.cloudfront.net/section-3-poster.png',
    applyNumberAnimation: true,
    twitter: 'https://twitter.com/NumbersHaveLife/status/1336395854404587529?s=20',
    instagram: 'https://www.instagram.com/p/CIjBHqjhitQ/?utm_source=ig_web_copy_link',
    facebook: 'https://www.instagram.com/p/CIjBHqjhitQ/?utm_source=ig_web_copy_link'
  },
  {
    heading: <>TO <b>BURY THEM ALL</b> IT WOULD TAKE</>,
    number: 364,
    unit: 'FOOTBALL FIELDS',
    finePrint: 'The average cemetery holds 1,250 plots per acre. A football field is 1.32 acres and would fit 1,650.',
    video: 'https://d2k57iveb9mkqe.cloudfront.net/section-4.mp4',
    shareVideo: 'https://d2k57iveb9mkqe.cloudfront.net/section-4-share.mp4',
    poster: 'https://d2k57iveb9mkqe.cloudfront.net/section-4-poster.png',
    applyNumberAnimation: true,
    twitter: 'https://twitter.com/NumbersHaveLife/status/1336396009468026880?s=20',
    instagram: 'https://www.instagram.com/p/CIjBpl-hz15/?utm_source=ig_web_copy_link',
    facebook: 'https://www.instagram.com/p/CIjBpl-hz15/?utm_source=ig_web_copy_link'
  },
  {
    heading: <>TO <b>FILL A STADIUM</b> THEY WOULD NEED</>,
    number: 8,
    unit: 'LAMBEAU FIELDS',
    finePrint: 'The capacity of Lambeau Field is 81,441.',
    video: 'https://d2k57iveb9mkqe.cloudfront.net/section-5.mp4',
    shareVideo: 'https://d2k57iveb9mkqe.cloudfront.net/section-5-share.mp4',
    poster: 'https://d2k57iveb9mkqe.cloudfront.net/section-5-poster.png',
    twitter: 'https://twitter.com/NumbersHaveLife/status/1336396221276098567?s=20',
    instagram: 'https://www.instagram.com/p/CIjB2USBRTk/?utm_source=ig_web_copy_link',
    facebook: 'https://www.instagram.com/p/CIjB2USBRTk/?utm_source=ig_web_copy_link'
  },
  {
    heading: <>TO <b>COUNT THEM</b> IT WOULD TAKE</>,
    number: 7,
    unit: 'DAYS',
    finePrint: 'If every person represented one second in time.',
    video: 'https://d2k57iveb9mkqe.cloudfront.net/section-6.mp4',
    shareVideo: 'https://d2k57iveb9mkqe.cloudfront.net/section-6-share.mp4',
    poster: 'https://d2k57iveb9mkqe.cloudfront.net/section-6-poster.png',
    twitter: 'https://twitter.com/NumbersHaveLife/status/1336397247160913920?s=20',
    instagram: 'https://www.instagram.com/p/CIjJlIaBQIf/?utm_source=ig_web_copy_link',
    facebook: 'https://www.instagram.com/p/CIjJlIaBQIf/?utm_source=ig_web_copy_link'
  },
  {
    heading: <>THEIR <b>COMBINED HEIGHT</b> WOULD REACH</>,
    number: <>2.4x</>,
    unit: <>THE INTERNATIONAL<br/> SPACE STATION</>,
    mobileUnit: 'ISS',
    finePrint: 'An average person’s height in the U.S. is 66.3 inches, for a total population height of 314 miles. The ISS is 254 miles above the earth.',
    video: 'https://d2k57iveb9mkqe.cloudfront.net/section-7.mp4',
    shareVideo: 'https://d2k57iveb9mkqe.cloudfront.net/section-7-share.mp4',
    poster: 'https://d2k57iveb9mkqe.cloudfront.net/section-7-poster.png',
    twitter: 'https://twitter.com/NumbersHaveLife/status/1336397482071351303?s=20',
    instagram: 'https://www.instagram.com/p/CIjJqxWhmPl/?utm_source=ig_web_copy_link',
    facebook: 'https://www.instagram.com/p/CIjJqxWhmPl/?utm_source=ig_web_copy_link'
  },
  {
    heading: <>THEY WOULD <b>POPULATE A CITY</b> THE SIZE OF</>,
    number: 1,
    unit: 'Baltimore, MD',
    finePrint: 'The population of Baltimore, MD is 593,490 people.',
    video: 'https://d2k57iveb9mkqe.cloudfront.net/section-8.mp4',
    shareVideo: 'https://d2k57iveb9mkqe.cloudfront.net/section-8-share.mp4',
    poster: 'https://d2k57iveb9mkqe.cloudfront.net/section-8-poster.png',
    twitter: 'https://twitter.com/NumbersHaveLife/status/1336397686971502593?s=20',
    instagram: 'https://www.instagram.com/p/CIjJ01wBBA2/?utm_source=ig_web_copy_link',
    facebook: 'https://www.instagram.com/p/CIjJ01wBBA2/?utm_source=ig_web_copy_link'
  },
  {
    type: 'intro',
    title: <><b>PLEASE GET VACCINATED.</b><br/> FOR YOUR LOVED ONES. <br/> FOR EVERYONE.</>,
    subtitle: <a href="https://www.cdc.gov/coronavirus/2019-ncov/index.html" target="_blank">CDC COVID-19 GUIDELINES</a>
  },
]



const determinePlacement = (itemIndex, activeIndex, {halfwayIndex, itemHeight, shuffleThreshold, visibleStyleThreshold}) => {
    // Position item in the center of list
    if (activeIndex === itemIndex) return 0;

    // Targeting items in the second part of the list
    if (itemIndex >= halfwayIndex) {
    // If moving backwards from index 0 to the last item, move the value downwards
    if (activeIndex > (itemIndex - halfwayIndex)) {
      return (itemIndex - activeIndex) * itemHeight;
    } else {
      // Negative value moves upwards towards the top of the list
      return -((SECTIONS.length + activeIndex) - itemIndex) * itemHeight;
    }
    }

    // Spacing for items after the current index
    if (itemIndex > activeIndex) {
    return (itemIndex - activeIndex) * itemHeight;
    }

    // Spacing for items before the current index
    if (itemIndex < activeIndex) {
    // If passing the negative threshold, move into a positive positioning
    if ((activeIndex - itemIndex) * itemHeight >= shuffleThreshold) {
      return (SECTIONS.length - (activeIndex - itemIndex)) * itemHeight;
    }
    // Move into a negative positioning
    return -(activeIndex - itemIndex) * itemHeight;
    }
 };

 const getIsMobile = () => {
   return window.matchMedia("(max-width: 700px)").matches
 }

class Home extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeSlideIndex: 0,
      shareDrawerOpen: false
    }

    this.wheelTimeout = null
    this.marker = true;
    this.counter1 = 0
    this.counter2 = false
    this.touchstartX = 0;
    this.touchstartY = 0;
    this.touchendX = 0;
    this.touchendY = 0;
    this.vh = window.innerHeight * 0.01;
  }

  componentDidMount() {
    window.addEventListener('wheel', debounce(this.handleWheel, 100))
    window.addEventListener('keyup', this.handleKeyUp)
    // document.getElementsByClassName('section-slide-bottom-share')[0].addEventListener('mouseover', this.toggleShareDrawer)
    window.addEventListener('touchstart', (event) => {
      this.touchstartX = event.changedTouches[0].screenX;
      this.touchstartY = event.changedTouches[0].screenY;
    }, false);

    window.addEventListener('touchend', this.handleGesture);

    document.documentElement.style.setProperty('--vh', `${this.vh}px`);
    // window.addEventListener('scroll', this.handleScroll)
  }

  handleGesture = (event) => {
    this.touchendX = event.changedTouches[0].screenX;
    this.touchendY = event.changedTouches[0].screenY;
    if (this.touchendY < this.touchstartY) {
      // swipe up
      // console.log('handle gesture, swiping up, going to next slide', this.touchendY, this.touchstartY)
      this.changeSlide(1)
    }

    if (this.touchendY > this.touchstartY) {
      // swipe down
      // console.log('handle gesture swiping down, going to prev slide ')
      this.changeSlide(-1)
    }
  }

  handleKeyUp = (e) => {
    if(e.which === 38) this.changeSlide(-1) //up key, go back a slide
    else if(e.which === 40) this.changeSlide(1) //down key, go forward a slide
  }

  handleWheel = (e) => {
    const direction = e.deltaY
    if(direction > 0 && this.state.activeSlideIndex === SECTIONS.length -1) return //already at the end
    if(direction < 0 && this.state.activeSlideIndex === 0) return //already at the end
    // this.counter1 += 1
    // if (this.marker) {this.wheelStart(direction)}
    this.changeSlide(direction > 0 ? 1 : -1)
  }

  wheelStart = (direction) =>{
  	this.marker = false;
  	this.wheelAct(direction);
  }

  wheelAct = (direction) =>{
   this.counter2 = this.counter1;
   this.wheelTimeout = setTimeout(() => {
     if (this.counter2 == this.counter1) {
       //wheelEnd
       this.marker = true;
       this.counter1 = 0
       this.counter2 = false;
       this.changeSlide(direction > 0 ? 1 : -1)
     } else {
       this.wheelAct(direction);
     }
   }, 50);
  }



  changeSlide = (direction = -1) => {
    let newIndex = this.state.activeSlideIndex + direction
    if(newIndex >= SECTIONS.length) return
    else if(newIndex < 0) return
    this.setState({
      activeSlideIndex: newIndex
    })
  }

  goToSlide = (slideIndex) => {
    this.setState({activeSlideIndex: slideIndex})
  }

  toggleShareDrawer = () => {
    this.setState({shareDrawerOpen: !this.state.shareDrawerOpen})
  }

  closeShareDrawer = () => {
    this.setState({shareDrawerOpen: false})
  }

  onScrollIndicatorClick = (e) => {
    e.stopPropagation()
    const isLastSlide = this.state.activeSlideIndex === SECTIONS.length - 1;
    if(isLastSlide) {
      this.goToSlide(0)
    } else {
      this.changeSlide(1)
    }
  }

  onNavLogoClick = () => {
    this.goToSlide(0);
  }

  renderScrollIndicator = (currentSlide, isIntroSlide) => {
    const isLastSlide = currentSlide === SECTIONS.length - 1;
    if(currentSlide >= 2 && !isLastSlide) return null //only show for first two slides
    return (
      <div
        className={classNames('section-slide-scroll-indicator', {'indicator-under-share-drawer': !isIntroSlide && !!this.state.shareDrawerOpen})}
        onClick={this.onScrollIndicatorClick}//(e) => isLastSlide ? this.goToSlide(0) : this.changeSlide(1)}
      >
        {isLastSlide ? <img style={{cursor: 'pointer'}} src={backToTopIcon} /> : <ScrollIndicator className={'scroll-indicator-icon'}/>}
      </div>
    )
  }
  numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  animateValue = (slideId, start, end, duration) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const number = document.getElementById(slideId);
      number.innerHTML = this.numberWithCommas(Math.floor(progress * (end - start) + start));
      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

  render() {
    const {
      activeSlideIndex,
      shareDrawerOpen
    } = this.state;

    // Determines which indexes fall within the second half of the slide array.
    // This lets us know which items we need to initially shuffle to the top of the list.
      // Used to determine which items appear above the active item
    const halfwayIndex = Math.ceil(SECTIONS.length / 2);

    //Sets an approximate height on each slide. This will control how far (in pixels) each carousel slide is spaced from the next.
    // Usd to determine the height/spacing of each item
    const itemHeight = window.innerHeight;
    // console.log('windowinnerhegiht', window.innerHeight)
    //This takes the previous constants and multiplies them together to create a number that will let us know when to move an item to the top or bottom of the list.
    // Used to determine at what point an item is moved from the top to the bottom
    const shuffleThreshold = halfwayIndex * itemHeight;


    //We need a way to prevent items from transitioning when they're being shuffled. (More on this in the next section).
    // Used to determine which items should be visible. this prevents the "ghosting" animation
    const visibleStyleThreshold = shuffleThreshold / 2;

    // const isLastSlide = activeSlideIndex === SECTIONS.length - 1;
    // console.log('active slide', activeSlideIndex, 'islast slide', isLastSlide)
    return (
      <div >
        <NavBar showNavBar={true} onNavLogoClick={this.onNavLogoClick}/>
        <div className="slides">
          <div className="carousel-inner">
            {/* <div
              className={classNames('section-slide-scroll-indicator')}
              onClick={this.onScrollIndicatorClick}//(e) => isLastSlide ? this.goToSlide(0) : this.changeSlide(1)}
              >
              {isLastSlide ? <img src={backToTopIcon} /> : <ScrollIndicator className={'scroll-indicator-icon'}/>}
            </div> */}
            <div className="carousel-dots">
              <NavigationDots
                numDots={SECTIONS.length}
                activeIndex={activeSlideIndex}
                onDotClick={this.goToSlide}
              />
            </div>
            {SECTIONS.map((section, i) => {

              const placement = determinePlacement(i, activeSlideIndex, {halfwayIndex, visibleStyleThreshold, shuffleThreshold, itemHeight})

              const sectionSlideClassName = classNames('section-slide', {
                'active': i === activeSlideIndex,
                'visible': Math.abs(placement) <= visibleStyleThreshold

              })

              let content;
              if(section.type === 'intro') {
                content = (
                  <>
                    <div className="section-slide-content-container intro-content-container" >
                      <div className={classNames("section-slide-stat-containers", { "fade-slide-animation": activeSlideIndex === i
                      })} >
                        {section.number && <Typography type="number-title" weight="bold" component="h1" id={'title-number'}>{i === activeSlideIndex && this.animateValue('title-number', 0, section.number, 2000)}</Typography>}
                        <Typography type="title" weight="light" component="h1" className={classNames({'hanging-quotes': i === 0})}>{section.title}</Typography>
                        <Typography type="subtitle" weight="light" style={{marginTop: 24}} component="h1">{section.subtitle}</Typography>
                      </div>
                    </div>
                    {this.renderScrollIndicator(i, true)}
                  </>
                )
              } else {
                const {
                  heading,
                  number,
                  unit,
                  finePrint,
                  video,
                  shareVideo,
                  social,
                  applyNumberAnimation,
                  facebook,
                  instagram,
                  twitter,
                  poster,
                  mobileUnit
                } = section
                content = (
                  <>
                    <div className="section-slide-content-container">
                      <div
                        className={classNames("section-slide-stat-container", {
                          "fade-slide-animation": activeSlideIndex === i
                        })}
                      >
                        <Typography type="heading" weight="light" className="leading-text" component="h1" >{heading}</Typography>
                        <Typography type="number" weight="bold" component="h1" id={`slide-number-${i}`}>{applyNumberAnimation ? i === activeSlideIndex && this.animateValue(`slide-number-${i}`, 0, number, 2000) : number}</Typography>
                        <Typography type="unit" weight="light" component="h1">{mobileUnit || unit}</Typography>
                        <Typography type="finePrint" weight="light" className="finePrintMobile" align="center">{finePrint}</Typography>
                      </div>
                      <SlideVideo
                        slide={i}
                        isActiveSlide={activeSlideIndex === i}
                        video={section.video}
                        poster={section.poster}
                        // isMobile={getIsMobile()}
                        getIsMobile={getIsMobile}
                      />
                      {/* <div className={classNames("section-slide-video", { "fade-animation": activeSlideIndex === i })} >
                        <video poster={poster} autoPlay loop muted inline="true" src={section.video} style={{height: '100%'}}/></div>
                      */}
                    </div>



                    <div className="section-slide-bottom-content">
                      <Typography type="finePrint" weight="light" className="finePrintDesktop" style={{flex: 1, display: "flex", alignItems: 'center', justifyContent: 'flex-start'}}>{finePrint}</Typography>
                      {this.renderScrollIndicator(i)}
                      <div className={classNames("section-slide-share-container")} style={{flex: 1}}>
                        <div className={classNames("share-icons-drawer", { "open": shareDrawerOpen })}>
                          <div
                            className="social-share-link"
                            onClick={() => window.open(twitter, '_blank', 'width=600,height=400')}
                          > <Twitter/> </div>
                          <div
                            className="social-share-link"
                            onClick={() =>window.open(instagram, '_blank', 'width=600,height=800')}
                            style={{width: 90, height: 50, color: 'white', display: 'flex', alignItems: 'center',  justifyContent: 'center'}}>
                            <Instagram/> </div>
                          <div
                            className="social-share-link"
                            onClick={() =>window.open(getFacebookShareLink(facebook), '_blank', 'width=600,height=400')}
                            style={{width: 90, height: 50, color: 'white', display: 'flex', alignItems: 'center',  justifyContent: 'center'}}>
                            <Facebook/> </div>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={shareVideo || video}
                            download={heading}
                            className="social-share-link"
                          >
                            <Download/>
                          </a>
                          <div
                            className="social-share-link"
                            onClick={this.closeShareDrawer}
                            style={{color: 'black'}}
                          >
                            <Close /></div>
                        </div>
                        <div
                          className={classNames("share-button", {
                            "open": !shareDrawerOpen
                          })}
                          // onMouseOver={this.toggleShareDrawer}
                          onClick={this.toggleShareDrawer}
                          style={{display: 'flex', alignItems: 'center'}}
                        >
                          SHARE
                        </div>


                      </div>
                    </div>
                  </>
                )
              }

              return (
                <div
                  // className={className}
                  className={sectionSlideClassName}
                  key={section.type + '-' + i}
                  style={{ transform: `translateY(${placement}px)` }}>

                  {content}
                </div>
              )

            })}
          </div>
        </div>
      </div>
    )
  }
}

export default Home
