import React from 'react';
import './Typography.css'

class CustomTypography extends React.Component {
  render() {
    const {
      align,
      embed,
      children,
      classes,
      className: propsClassName = '',
      color = 'white',
      // component,
      fontSize,
      style,
      type,
      variant,
      weight,
      component,
      // id: idProp = '',
      ...restProps
    } = this.props;

    let className = 'typography'
    if(type != null) className += ` typography-${variant}`
    if(type != null) className += ` typography-${type}`
    if(color != null) className += ` typography-${color}`
    if(weight != null) className += ` typography-${weight}`
    if(align != null) className += ` typography-${align}`
    className += ` ${propsClassName}`

    const allProps = {
      className,
      style: {
        fontSize,
        ...style,
      },
      ...restProps,
    };
    if(component === 'h1') return <h1 {...allProps}>{children}</h1>
    if (embed) return <span {...allProps}>{children}</span>;
    return ( <div {...allProps}>{children}</div>
    );
    // return (
    //   <span
    //     component={component}
    //     className={className}
    //     style={{
    //       fontSize,
    //       ...style,
    //     }}
    //     {...restProps}
    //   >
    //     {children}
    //   </span>
    // );
  }
}

export default CustomTypography
