const Twitter = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20" {...props}>
        <g fill="none" fillRule="evenodd">
            <g fill="#000" fillRule="nonzero">
                <g>
                    <g>
                        <g>
                            <path d="M24 2.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195C19.313.846 18.032.248 16.616.248c-3.179 0-5.515 2.966-4.797 6.045C7.728 6.088 4.1 4.128 1.671 1.149c-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" transform="translate(-1054 -815) translate(73 322) translate(941 480) translate(40 13)"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>

  )
}

export default Twitter
