// Originally inspired by  David Walsh (https://davidwalsh.name/javascript-debounce-function)

// // Returns a function, that, as long as it continues to be invoked, will not
// // be triggered. The function will be called after it stops being called for
// // `wait` milliseconds.
// const debounce = (func, wait) => {
//   let timeout;
//
//   // This is the function that is returned and will be executed many times
//   // We spread (...args) to capture any number of parameters we want to pass
//   return function executedFunction(...args) {
//
//     // The callback function to be executed after
//     // the debounce time has elapsed
//     const later = () => {
//       // null timeout to indicate the debounce ended
//       timeout = null;
//
//       // Execute the callback
//       func(...args);
//     };
//     // This will reset the waiting every function execution.
//     // This is the step that prevents the function from
//     // being executed because it will never reach the
//     // inside of the previous setTimeout
//     clearTimeout(timeout);
//
//     // Restart the debounce waiting period.
//     // setTimeout returns a truthy value (it differs in web vs Node)
//     timeout = setTimeout(later, wait);
//   };
// };

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate = true) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};


export default debounce
