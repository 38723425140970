import React from 'react';
import './Sidebar.css'
import classNames from '../../../utils/classNames'

class Sidebar extends React.Component {
  render() {
    const {
      show
    } = this.props;
    return (
      <div className={classNames("sidebar-drawer", {
        "show": show
      })}>
        <div className="sidebar-phrase">
          Listen to scientists, wear a mask
        </div>
        <a className="sidebar-contact" target='_blank' rel="noreferrer" href='mailto:info@numbershavelife.com'>
          Contact
        </a>
      </div>
    )
  }
}

export default Sidebar