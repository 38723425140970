const Download = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#000" fillRule="nonzero">
          <g>
            <g>
              <g>
                <path d="M17.38 11.203h-3.52V1.07c0-.293-.237-.531-.53-.533H11.2c-.294 0-.533.239-.533.533v10.134H7.154c-.216 0-.411.13-.494.329-.083.2-.037.43.116.582l5.49 5.49 5.49-5.49c.152-.153.198-.382.116-.581-.083-.2-.277-.33-.493-.33z" transform="translate(-281 -805) translate(0 766) translate(281 39) translate(0 -.003)"/>
                <path d="M21.333 16.002v3.732H3.2v-3.732c0-.295-.239-.533-.533-.533H1.6c-.294 0-.533.238-.533.533v4.8c0 .589.478 1.067 1.067 1.067H22.4c.59 0 1.067-.478 1.067-1.067v-4.8c0-.142-.056-.278-.156-.378-.1-.1-.237-.156-.378-.155h-1.066c-.141 0-.277.055-.378.155-.1.1-.156.236-.156.378z" transform="translate(-281 -805) translate(0 766) translate(281 39) translate(0 -.003)"/>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>

  )
}

export default Download
