const ScrollIndicator = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="45" viewBox="0 0 30 45" {...props}>
      <g fill="none" fillRule="evenodd">
        <g stroke="#FFF">
          <g>
            <g>
              <path d="M0 0.078L15 15.078 30 0.078" transform="translate(-705 -2444) translate(705 804) translate(0 1640)"/>
              <path strokeWidth="2" d="M0 15.078L15 30.078 30 15.078" transform="translate(-705 -2444) translate(705 804) translate(0 1640)"/>
              <path strokeWidth="3" d="M0 29.245L15 44.245 30 29.245" transform="translate(-705 -2444) translate(705 804) translate(0 1640)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ScrollIndicator
