import './NavigationDots.css'
import Typography from '../Typography'

const NavigationDots = (props) => {
  const {
    numDots,
    activeIndex,
    onDotClick
  } = props;

  const onClick = (index) => (e) => {
    onDotClick(index)
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start'}}>
      {Array.from(Array(numDots)).map((_item, index) => {
        let className = 'nav-dot'
        if(index === activeIndex) className += ' nav-dot-filled'
        return (
          <div
            className={className}
            onClick={onClick(index)}
          />
        )
      })}
      {activeIndex + 1 < numDots && <Typography color="white" fontSize={8} onClick={onClick(activeIndex + 1)}>scroll</Typography>}
    </div>
  )
}

export default NavigationDots
