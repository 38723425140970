const Close = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" {...props}>
      <g fill="none" fillRule="evenodd" strokeLinecap="square">
        <g stroke="#000" strokeWidth="3">
          <g>
            <g>
              <path d="M0.5 0.5L21.5 21.5" transform="translate(-365 -805) translate(0 766) translate(367 41)"/>
              <path d="M0.5 0.5L21.5 21.5" transform="translate(-365 -805) translate(0 766) translate(367 41) matrix(-1 0 0 1 22 0)"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Close
